<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              <feather-icon
                icon="ArrowLeftIcon"
                size="24"
                class="mr-2"
                @click="goBack"
              />Vaccine
            </h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Add </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form action="apps-purchase" method="post" @submit="addVaccine">
        <b-card>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Purchase Date<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="purchase_date"
                  rules="required"
                >
                  <b-form-datepicker
                    name="purchase_date"
                    v-model="FormData.purchase_date"
                    menu-class="w-100"
                    calendar-width="100%"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Vaccine Name<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Vaccine Name"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedVaccine"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="vaccineList"
                      label="vaccine_name"
                      clearable:false
                      :reduce="(e) => e.slug"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Source<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Source"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedSource"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sourceList"
                      label="name"
                      clearable:false
                      :reduce="(e) => e.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Route<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Route"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedRoute"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="routeList"
                      label="name"
                      clearable:false
                      :reduce="(e) => e.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Unit Name<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Unit Name"
                    rules="required"
                  >
                    <v-select
                      v-model="selectedUnit"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="unitList"
                      label="name"
                      clearable:false
                      :reduce="(e) => e.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Expiry date<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name=" Expiry date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="FormData.expiry_date"
                    name="expiry_date"
                    menu-class="w-100"
                    calendar-width="100%"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Total Vaccines Received<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Total Vaccines Received"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.total_stock"
                      placeholder="Total Vaccines Received"
                      type="text"
                      name="total_stock"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Price Per Vaccine<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="  Price Per Vaccine"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.price_per_vaccine"
                      placeholder="Price per Vaccine"
                      type="text"
                      name="price_per_vaccine"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col>
              <b-button
                type="submit"
                variant="primary"
                value="Submit"
                class="mr-1"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BFormDatepicker,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormSelect,
    BFormDatepicker,
    flatPickr,
    vSelect,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      selected: null,
      FormData: {
        total_stock: null,
        purchase_date: null,
        expiry_date: null,
        price_per_vaccine: null,
      },
      selectedVaccine: null,
      selectedSource: null,
      selectedUnit: null,
      selectedRoute: null,
      unitList: [],
      routeList: [],
      breedList: [],
      vaccineList: [],
      sourceList: [],
    };
  },
  created: function () {
    this.getUnit();
    this.getRoute();
    this.getSource();
    this.getVaccine();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getSource() {
      axiosIns
        .get(`web/get-all-source`)
        .then((response) => {
          this.sourceList = response.data.source_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getVaccine() {
      axiosIns
        .get(`web/get-all-vaccines`)
        .then((response) => {
          this.vaccineList = response.data.vaccine_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getUnit() {
      axiosIns
        .get(`web/units/vaccine`)
        .then((response) => {
          this.unitList = response.data.unitList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getRoute() {
      axiosIns
        .get(`web/routeType/vaccine`)
        .then((response) => {
          this.routeList = response.data.routeList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getSelectedUnit(id) {
      this.selectedUnit = id;
    },
    getSelectedRoute(id) {
      this.selectedRoute = id;
    },
    addVaccine(e) {
      e.preventDefault();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var data = new FormData();
          data.append("vaccine_slug", this.selectedVaccine);
          data.append("source", this.selectedSource);
          data.append("vaccine_qty", this.FormData.total_stock);
          data.append("purchase_date", this.FormData.purchase_date);
          data.append("expiry_date", this.FormData.expiry_date);
          data.append("unit", this.selectedUnit);
          data.append("route", this.selectedRoute);
          data.append("price_per_vaccine", this.FormData.price_per_vaccine);
          const hatchery_id = this.$route.params.hatcheryId;
          axiosIns
            .post(`web/hatchery/${hatchery_id}/purchaseVaccine`, data)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Data Successfully Added `,
                },
              });
              this.$router.push({
                name: `apps-purchase`,
              });
            })
            .catch((error) => {
              const data = error.response.data.errors;
              data, "data";
              let arr = Object.values(data).flat().toString(",");

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: arr,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
